import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { loggedInGuard } from './core/guards/logged-in.guard';

export const routes: Routes = [
    {
        path: '',
        canActivate: [loggedInGuard],
        loadComponent: () => import('./layouts/auth/auth.layout').then(c => c.AuthLayout),
        children: [
            {
                path: '',
                loadComponent: () => import('./layouts/auth/routes/login/login.component').then(c => c.LoginComponent)
            }
        ]
    },
    {
        path: 'invite/:id',
        loadComponent: () => import('./layouts/invite/invite.component').then(c => c.InviteComponent)
    },
    {
        path: 'dashboard',
        canActivate: [authGuard],
        loadComponent: () => import('./layouts/dashboard/dashboard.layout').then(c => c.DashboardLayout),
        children: [
            {
                path: 'departments-management',
                loadComponent: () => import('./layouts/dashboard/routes/departments/departments.component').then(c => c.DepartmentsComponent)
            },
            {
                path: 'departments-management/roles-management',
                loadComponent: () => import('./layouts/dashboard/routes/departments/routes/roles/roles.component').then(c => c.RolesComponent)
            },
            {
                path: 'gates-management',
                loadComponent: () => import('./layouts/dashboard/routes/gates-management/gates-management.component').then(c => c.GatesManagementComponent)
            },
            {
                path: 'devices-management',
                loadComponent: () => import('./layouts/dashboard/routes/devices-management/devices-management.component').then(c => c.DevicesManagementComponent)
            },
            {
                path: 'helpers',
                loadComponent: () => import('./layouts/dashboard/routes/helper-directory/helper-directory.component').then(c => c.HelpersDirectoryComponent)
            },
            {
                path: 'helpers/add-helper',
                loadComponent: () => import('./layouts/dashboard/routes/helper-directory/routes/add-helper/add-helper.component').then(c => c.AddHelpersComponent)
            },
            {
                path: 'helpers/edit-helper/:id',
                loadComponent: () => import('./layouts/dashboard/routes/helper-directory/routes/add-helper/add-helper.component').then(c => c.AddHelpersComponent)
            },
            {
                path: 'staff-directory',
                loadComponent: () => import('./layouts/dashboard/routes/staff-directory/staff-directory.component').then(c => c.StaffDirectoryComponent)
            },
            {
                path: 'staff-directory/edit-staff/:id',
                loadComponent: () => import('./layouts/dashboard/routes/staff-directory/routes/add-staff/add-staff.component').then(c => c.AddStaffComponent)
            },
            {
                path: 'staff-directory/add-staff',
                loadComponent: () => import('./layouts/dashboard/routes/staff-directory/routes/add-staff/add-staff.component').then(c => c.AddStaffComponent)
            },
            {
                path: 'quick-contacts',
                loadComponent: () => import('./layouts/dashboard/routes/quick-contacts/quick-contacts.component').then(c => c.QuickContactsComponent)
            },
            {
                path: 'patrol-check-points',
                loadComponent: () => import('./layouts/dashboard/routes/patrol-checkpoints/patrol-checkpoints.component').then(c => c.PatrolCheckpointsComponent)
            },
            {
                path: 'patrol-shifts',
                loadComponent: () => import('./layouts/dashboard/routes/patrol-shifts/patrol-shifts.component').then(c => c.PatrolShiftsComponent)
            },
            {
                path: 'patrol-logs',
                loadComponent: () => import('./layouts/dashboard/routes/patrol-logs/patrol-logs.component').then(c => c.PatrolLogsComponent)
            },
            {
                path: 'accounts-transfers',
                loadComponent: () => import('./layouts/dashboard/routes/accounts-and-transfers/accounts-and-transfers.component').then(c => c.AccountsAndTransfersComponent)
            },
            {
                path: 'flats',
                loadComponent: () => import('./layouts/dashboard/routes/flats/flats.component').then(c => c.FlatsComponent)
            },
            {
                path: 'flats/activate-flat/:id',
                loadComponent: () => import('./layouts/dashboard/routes/flats/routes/activate-flat/activate-flat.component').then(c => c.ActivateFlatComponent)
            },
            {
                path: 'flats/change-ownership/:id',
                loadComponent: () => import('./layouts/dashboard/routes/flats/routes/change-ownership/change-ownership.component').then(c => c.ChangeOwnershipComponent)
            },
            {
                path: 'helpdesk-tickets',
                loadComponent: () => import('./layouts/dashboard/routes/helpdesk-tickets/helpdesk-tickets.component').then(c => c.HelpdeskTicketsComponent)
            },
            {
                path: 'helpdesk-tickets/ticket/:id',
                loadComponent: () => import('./layouts/dashboard/routes/helpdesk-tickets/routes/ticket/ticket.component').then(c => c.TicketComponent)
            },
            {
                path: 'parcel-logs',
                loadComponent: () => import('./layouts/dashboard/routes/parcel-logs/parcel-logs.component').then(c => c.ParcelLogsComponent)
            },
            {
                path: 'helpdesk-setup',
                loadComponent: () => import('./layouts/dashboard/routes/helpdesk-setup/helpdesk-setup.component').then(c => c.HelpdeskSetupComponent)
            },
            {
                path : 'helpdesk-settings',
                loadComponent : () => import('./layouts/dashboard/routes/helpdesk-setup/routes/helpdesk-settings/helpdesk-settings.component').then(c => c.HelpdeskSettingsComponent)
            },
            {
                path: 'access-logs',
                loadComponent: () => import('./layouts/dashboard/routes/access-logs/access-logs.component').then(c => c.AccessLogsComponent)
            },
            {
                path: 'work-logs',
                loadComponent: () => import('./layouts/dashboard/routes/work-logs/work-logs.component').then(c => c.WorkLogsComponent)
            },
            {
                path: 'work-packages',
                loadComponent: () => import('./layouts/dashboard/routes/work-packages/work-packages.component').then(c => c.WorkPackagesComponent)
            }
        ],
    },
    { path: '**', redirectTo: '' }
];